<div class="notFound__hero">
  <img
    src="https://media-porfolio.s3.us-east-2.amazonaws.com/images/404_error.png"
    alt="astronaut"
  />
  <div class="container-astro">
    <div class="img-astro">
      <a [routerLink]="['/']">
        <img
          src="https://media-porfolio.s3.us-east-2.amazonaws.com/images/astronauta.png"
          alt="astro"
        />
      </a>
    </div>
  </div>
  <p>Page not Found please click on the astronaut to return to homePage</p>
</div>
